import React, { useEffect, useState, useReducer, useRef } from "react";
import { Pagination } from 'antd';
import DatePicker from "react-datepicker";
import Loading from "../../utils/loading/Loading";
import { useNavigate } from "react-router-dom";
import { debounce } from "../../global";
import searchicon from "../../images/ic-search.svg";
import Header from "../../components/common/Header/container";
import leftArrow from "../../images/left-arrow.svg";
import Download from "../../components/svgIcons/Download";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import moment from "moment-timezone"
import { DataGrid } from "@mui/x-data-grid"
import ClearDates from "../../utils/clearDates/ClearDates";

const REPORT_HEADERS = [
  "Lot",
  "Address",
  "No. of Visits",
  "No. of Tire Tags",
  "Paid Tire Tags",
  "Unpaid Tire Tags",
  "Voided Tags",
  "No. of Mail-Ins",
  "Paid Mail-Ins",
  "Unpaid Mail-In",
  "Voided Mail-Ins",
  "No. of Taggr Notice",
  "Paid Taggr Notice",
  "Unpaid Taggr Notice",
  "Voided Taggr Notice",
  "Paid EL",
  "Unpaid EL",
  "Voided EL",
  "Reduced Tags",
  "Reduced Mail-Ins",
  "Reduced Taggr Notice",
  "Revenue From Tags",
  "Revenue from Mail-Ins",
  "Revenue from Taggr Notice",
  "Total Revenue"
]

export default function ReportComponent(props) {
  const tz = moment.tz.guess()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [showList, setShowList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [roleTitle, setRoleTitle] = useState("");
  const searchInputRef = useRef();

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initialPageState)

  useEffect(() => {
    getUserPermissions();
  }, []);
  
  const [name, setName] = useState("");
  const getUserPermissions = async () => {
    setLoading(true);
    try {     
      const { value } = await props.user();
      if (value?.success) {
        getLotList(1);
        setName(value?.user?.fullname);
        setRoleTitle(value?.user?.role);
        setPermissions(value?.user?.roleId?.permissions);
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    } finally {
      setLoading(false)
    }
  };

  async function getLotList(page, isLoading=true, dateFilter=true) {
    setLoading(isLoading);
    const { value } = await props.lotprovider({ page, size: 10, startDate: dateFilter ? startDate : null, endDate: dateFilter ? endDate : null, type: "operatorReport" });
    if (value?.success) {
      setShowList(value?.lots);
      setPageState({ total: value?.total })
    }
    setLoading(false);
  };

  const search = async query => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator('lot', query)
        if (value?.success) {
          console.log(value?.results)
          setShowList(value?.results)
          setPageState({ total: 0 })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  async function getallLotList() {
    const { value } = await props.lotprovider({ startDate, endDate, type: "operatorReport" });
    if (value?.success) {
      const data = [
        REPORT_HEADERS,
        ...value?.lots.map((lot) => [
          lot?.lotname,
          `"${lot?.address ?? "-"}"`,
          lot?.visits ?? 0,
          lot?.number_of_tire_tags ?? 0,
          lot?.number_of_paid_tire_tags ?? 0,
          lot?.number_of_unpaid_tire_tags ?? 0,
          lot?.voided_tire_tags ?? 0,
          lot?.number_of_mail_ins ?? 0,
          lot?.number_of_paid_mail_ins ?? 0,
          lot?.number_of_unpaid_mail_ins ?? 0,
          lot?.voided_mail_ins ?? 0,
          lot?.number_of_tn ?? 0,
          lot?.number_of_paid_tn ?? 0,
          lot?.number_of_unpaid_tn ?? 0,
          lot?.voided_tn ?? 0,
          lot?.number_of_paid_el ?? 0,
          lot?.number_of_unpaid_el ?? 0,
          lot?.voided_el ?? 0,
          lot?.reduced_tire_tags ?? 0,
          lot?.reduce_mail_ins ?? 0,
          lot?.reduce_tn ?? 0,
          lot?.revenue_from_tire_tag ?? 0,
          lot?.revenue_from_mail_ins ?? 0,
          lot?.revenue_from_tn ?? 0,
          lot?.total_operator_revenue_amount ?? 0,
        ]),
      ];
      const csv = data.map(row => row.join(",")).join("\n");
      const blob = new Blob([csv], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      // link.download = "data.csv";
      link.download = `${name}-All-Lot${getCurrentDateTime()}.csv`;
      link.click();
      URL.revokeObjectURL(url);
    }
  }


  function handleSubmit() {
    if (!startDate || !endDate) {
      dispatch(setAlert('error', "Please select start date and end date"))
    } else {
      setPageState({ currentPage: 1 })
      setLoading(true)
      getLotList(1);
    }
    setTimeout(() => {
      setLoading(false);
    }, 300);
    // setLoading(false)
  }

  function clearDates() {
    setStartDate(null)
    setendDate(null)
    setLoading(true)
    getLotList(1, true, false);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }
  const getCurrentDateTime = () => {
    const now = moment().tz(tz);
    return now.format("YYYY-MM-DD_HH-mm-ss");
  };

  const columns = [
    { field: 'lotname', headerName: 'Lot', width: 150 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'visits', headerName: 'No. of Visits', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_tire_tags', headerName: 'No. of Tire Tags', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_paid_tire_tags', headerName: 'Paid Tire Tags', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_unpaid_tire_tags', headerName: 'Unpaid Tire Tags', width: 150, valueGetter: (params) => params || 0 },
    { field: 'voided_tire_tags', headerName: 'Voided Tags', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_mail_ins', headerName: 'No. of Mail-Ins', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_paid_mail_ins', headerName: 'Paid Mail-Ins', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_unpaid_mail_ins', headerName: 'Unpaid Mail-Ins', width: 150, valueGetter: (params) => params || 0 },
    { field: 'voided_mail_ins', headerName: 'Voided Mail-Ins', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_tn', headerName: 'No. of Taggr Notice', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_paid_tn', headerName: 'Paid Taggr Notice', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_unpaid_tn', headerName: 'Unpaid Taggr Notice', width: 150, valueGetter: (params) => params || 0 },
    { field: 'voided_tn', headerName: 'Voided Taggr Notice', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_paid_el', headerName: 'Paid EL', width: 150, valueGetter: (params) => params || 0 },
    { field: 'number_of_unpaid_el', headerName: 'Unpaid EL', width: 150, valueGetter: (params) => params || 0 },
    { field: 'voided_el', headerName: 'Voided EL', width: 150, valueGetter: (params) => params || 0 },
    { field: 'reduced_tire_tags', headerName: 'Reduced Tags', width: 150, valueGetter: (params) => params || 0 },
    { field: 'reduce_mail_ins', headerName: 'Reduced Mail-Ins', width: 150, valueGetter: (params) => params || 0 },
    { field: 'reduce_tn', headerName: 'Reduced Taggr Notice', width: 150, valueGetter: (params) => params || 0 },
    { 
      field: 'revenue_from_tire_tag', 
      headerName: 'Revenue From Tags', 
      width: 150, 
      valueGetter: (params) => params || 0, 
      valueFormatter: (params) => `$${params}`
    },
    { 
      field: 'revenue_from_mail_ins', 
      headerName: 'Revenue from Mail-Ins', 
      width: 180, 
      valueGetter: (params) => params || 0, 
      valueFormatter: (params) => `$${params}`
    },
    { 
      field: 'revenue_from_tn', 
      headerName: 'Revenue from Taggr Notice', 
      width: 180, 
      valueGetter: (params) => params || 0, 
      valueFormatter: (params) => `$${params}`
    },
    { 
      field: 'total_operator_revenue_amount', 
      headerName: 'Total Revenue', 
      width: 150, 
      valueGetter: (params) => params || 0, 
      valueFormatter: (params) => `$${params}`
    },
  ];


  return (
    <>
      {isLoading && <Loading />}
      <section className="mainWrapper">
        <Header />
        <div className="main-wrapper" id="profile-wrapper">
          <h4 className="d-flex align-items-center mobileTitle">
            <img
              src={leftArrow}
              className="left-arrow"
              onClick={() => navigate(-1)}
            />
            Reports
          </h4>
          <div className="datepicker-block">
            <div className="date-picker-wrapper">
              <div className="left-block">
                <div className="date-picker">
                  <div className="date-picker-list">
                    <label>Start Date </label>

                    <DatePicker
                      selected={startDate}
                      // onChange={(date) => setStartDate(date)}
                      onChange={(date) =>
                        setStartDate(moment(date).tz(tz).toDate())
                      }
                      placeholderText="--/--/----"
                      className="date-picker"
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="date-picker-list">
                    <label>End Date</label>

                    <DatePicker
                      selected={endDate}
                      // onChange={(date) => setendDate(date)}
                      // onChange={(date) =>
                      //   setendDate(moment(date).tz(tz).toDate())
                      // }
                      onChange={(date) => {
                        const newDate = moment(date).tz(tz);
                        newDate.set({ hour: 23, minute: 59, second: 59 });
                        setendDate(newDate.toDate());
                      }}
                      placeholderText="--/--/----"
                      className="date-picker"
                      minDate={startDate}
                      maxDate={new Date()}
                      disabled={!startDate}
                    />
                  </div>
                </div>
                <div className="date-picker-btn">
                  <button className="btn DarkBtn" onClick={handleSubmit}>
                    Submit
                  </button>
                  <ClearDates onClick={clearDates} className="me-2"/>
                  {(permissions?.Report_download ||
                    roleTitle === "operator") && (
                    <button
                      className="btn DarkBtn download-btn"
                      onClick={async () => {
                        await getallLotList();
                      }}
                    >
                      {" "}
                      <Download />
                      Download
                    </button>
                  )}
                </div>
              </div>
              <div class="search-lot">
                <button class="btn" type="button">
                  <img src={searchicon} />
                </button>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  ref={searchInputRef}
                  onChange={async (e) => {
                    e?.target?.value?.length > 2 &&
                      debounce(async() => await search(e?.target?.value), 500);
                    e?.target?.value?.length === 0 && getLotList(1);
                  }}
                />
              </div>
            </div>
          </div>
          {
            <div className="lotsWrapper">
              <div className="table-responsive">
                <DataGrid 
                  rows={
                    showList?.map((data, index) => ({
                        ...data,
                        // total_revenue: (user?.revenue_from_tire_tag ?? 0) + (user?.revenue_from_mail_ins ?? 0)
                    })
                  )}
                  columns={columns}
                  pageSize={pageState.pageSize}
                  rowsPerPageOptions={[10]}
                  // hideFooterPagination
                  // hideFooterSelectedRowCount
                  hideFooter
                  pagination
                  onPageChange={(params) => setPageState({ currentPage: params.page })}
                  rowCount={pageState.total}
                  paginationMode="server"
                  loading={props.loading}
                  getRowId={(row) => row._id}
                />
                </div>
                {showList?.length > 0 && (
                  <div className="pagination-block">
                    <Pagination
                      hideOnSinglePage
                      defaultCurrent={pageState?.defaultCurrentPage}
                      onChange={(page) => {
                          getLotList(page)
                          setPageState({ currentPage: page })
                        }
                      }
                      current={pageState?.currentPage}
                      pageSize={pageState?.pageSize}
                      total={pageState?.total}
                      showSizeChanger={false}
                    />
                  </div>
                )}
            </div>
          }
        </div>
      </section>
    </>
  );
}
