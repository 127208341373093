import React from 'react';
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import { Download } from '@mui/icons-material';
import ClearDates from '../../../utils/clearDates/ClearDates';

export default function CustomDatePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleDateSubmit,
  clearDates,
  csvData,
  noticeFields,
}) {
  const tz = moment.tz?.guess()
  return (
    <div className="admin-citation-page">
              <div className="date-picker-block mb-4 mt-4" style={{marginLeft: "5%"}}>
                <div className="d-flex align-items-center border-block">
                    <div className="date-picker-list">
                        <label>Start Date</label>
                        <DatePicker
                            selected={startDate}
                            // onChange={(date) => setStartDate(date)}
                            onChange={(date) =>
                                setStartDate(moment(date).tz(tz).hour(6).minute(0).toDate())
                            }
                            placeholderText="--/--/----"
                            className="date-picker"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="date-picker-list">
                        <label>End Date</label>
                        <DatePicker
                            selected={endDate}                                             
                            // onChange={(date) =>
                            //     setendDate(moment(date).tz(tz).toDate())
                            // }
                            onChange={(date) => {
                                const newDate = moment(date).tz(tz);
                                newDate.set({ hour: 23, minute: 59, second: 59 });
                                setEndDate(newDate.toDate());
                            }}                                                
                            placeholderText="--/--/----"
                            className="date-picker"
                            minDate={startDate}
                            maxDate={new Date()}
                            disabled={!startDate}
                        />
                    </div>
                </div>
                <div className="operator-btns">
                  <div className="border-block-btn">
                    <button className="btn DarkBtn" onClick={handleDateSubmit} >Submit</button>
                  </div>
                </div>
                  <ClearDates 
                  onClick={clearDates}
                  className="me-2"/>
                {csvData?.citation?.length > 0 ? (<CSVLink
                data={[
                  noticeFields, // header row
                  ...(csvData?.citation?.map((p) => [
                      p?.citation_type,
                      p?.citation_type !== "mail_in" ? p?.qr_code ?? p?.tiretag_citation_data?.citation_number ?? p?.citation_number  : p?.parkpliant_reference_id,
                      p?.lotdata?.address,
                      p?.lotdata?.internalAccountingId,
                      p?.vehicle?.licensePlate,
                      p?.state,
                      p?.vehicle?.make,
                      p?.refund?.length > 0 && p?.refund[0]?.refund_type === "partial" ? "Partial Refund" : p?.payment_status,
                      p?.break_down_amount?.calculative_amount,
                      (p?.break_down_amount?.calculative_amount ?? 0) + (p?.break_down_amount?.stripe_fees_offence ?? 0) + (p?.break_down_amount?.dock_fees ?? 0) + (p?.break_down_amount?.late_fee ?? 0),
                      p?.payment_date,
                      p?.taggr?.fullname,
                      p?.createdAt,
                      (p?.citation_type === "tag_vehicle" && p?.payment_status === "pending"
                        || (p?.payment_status === "voided" && p?.holdTaggrFee )) ? 0 : p?.break_down_amount?.taggr?.amount,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 : p?.break_down_amount?.operator?.amount,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 :  p?.break_down_amount?.tire_tag?.amount,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 : p?.break_down_amount?.taggr_solutions?.amount,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 : p?.break_down_amount?.dock_fees,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 : p?.break_down_amount?.total_stripe_fees,
                      p?.break_down_amount?.service_fee,
                    ]
                  ) || []) // map data to rows
                ]}
                filename={"TagsDeployed.csv"}
                className="btn DarkBtn download-btn"
                style={{height: "fit-content"}}
              >
                <Download/>
                  Download
              </CSVLink>
              ) : (
                <button
                  className="btn DarkBtn download-btn"
                  style={{height: "fit-content"}}
                  onClick={(e) => e.preventDefault()} // prevents any action
                  disabled
                >
                  <Download/>
                  Download
                </button>
              )}
              </div>          
              </div>
  );
};
