import React, { useEffect, useReducer, useState } from "react";
import brandLogo from "../../images/brand-logo-black.svg";
import { Link , useNavigate } from "react-router-dom";
import ImgLogin from "../../images/login-img.png";
import icEye from "../../images/ic-eye.svg";
import icEyec from "../../images/ic-eyec.svg";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
export default function ChangePasswordComponent(props) {

    const dispatch = useDispatch()

    const [Name, setName] = useState('')
    const [userId, setuserId] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState(null);
    const [errorMessage2, setErrorMessage2] = useState('');
    const [errorMessage3, setErrorMessage3] = useState('');
    const [errorMessage4, setErrorMessage4] = useState('');

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [showpassw, setShowPassw] = useState(false)
    const [showpassw1, setShowPassw1] = useState(false)
    const [showpassw2, setShowPassw2] = useState(false)

    const [img, setImg] = useState(icEyec)
    const [img1, setImg1] = useState(icEyec)
    const [img2, setImg2] = useState(icEyec)

    const navigate = useNavigate();


    const visiblePassw = (e) => {
        e.preventDefault()
        setShowPassw(!showpassw)
        if (img === icEye) {
            setImg(icEyec)
        } else {
            setImg(icEye)
        }
    }
    const visiblePassw1 = (e) => {
        e.preventDefault()
        setShowPassw1(!showpassw1)
        if (img1 === icEye) {
            setImg1(icEyec)
        } else {
            setImg1(icEye)
        }
    }
    const visiblePassw2 = (e) => {
        e.preventDefault()
        setShowPassw2(!showpassw2)
        if (img2 === icEye) {
            setImg2(icEyec)
        } else {
            setImg2(icEye)
        }
    }


    useEffect(() => {
        //      const fetchData = async () => {
        //   await fetchName();
        //   if (userId) {
        //     await update(userId);
        //   }
        // }
        // fetchData();
        fetchName();
        console.log(dispatch,"-------");
    }, []);


    const fetchName = async () => {

        try{
        const { value } = await props.user();
        if (value?.success) {
            setName(value);
            setuserId(value?.user?._id)
            setUserEmail(value?.user.email)
            const checkAuth = localStorage.getItem("xC1hd")
        }else{
            if(value?.message === "Authentication token expired | Unauthorized"){
                navigate("/login")
            dispatch(setAlert("error", value?.message))
        }
    }
    }catch (error) {
        dispatch(setAlert("error", error?.message))
      }
    };


    const handleSubmit = async () => {

        if (oldPassword.length === 0 || newPassword.length === 0 || confirmPassword.length === 0) {
            setError(true);
            return;
          }

        const checkAuth = localStorage.getItem("xC1hd");
        const checkOldPassword = await props.logins({ email: userEmail, password: oldPassword });



        // if (!checkOldPassword?.value?.success) {
        //     alert('Old password is incorrect');
        //     return;
        // }
        if (!checkOldPassword?.value?.success) {
            // alert('Old password is incorrect');
            setError("Old password is incorrect")
             return;
          }

        if (newPassword !== confirmPassword) {
            setErrorMessage4('Password does not match');
            return;
        }

        if( newPassword.length < 7 ){
            setErrorMessage2("Your password must have atleast 8 characters")
            return;
          }

          if(confirmPassword.length < 7){
            setErrorMessage3("Your password must have atleast 8 characters")
            return;
          }




        const { value } = await props.updateUser(
            // userId: userId,
            // newPassword: newPassword,
            userId, { password: newPassword }
            // "63bfbb60af684683bab37cf5", { password: newPassword }
        );

        if (value?.success) {
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setError(null);
            setSuccess(true);
            localStorage.removeItem("xC1hd");
            navigate("/login")
            dispatch(setAlert("success", "Password updated successfully!"))
        } else {
            // setError(value?.error || "An error occurred while updating password.");
            setSuccess(false);
            dispatch(setAlert("error", value?.message))
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
          handleSubmit();
        }
      };


    return (
        <>
            <div>
                <section className="MainLoginWrapper">
                    <div className="FlexDiv">
                        <div className="LeftSection">
                            <div className="LeftSectionInner">
                                {/* <div> */}
                                <Link
                      className="nav-link "
                    >
                                    <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
                                    <h3>Enforcement simplified </h3>
                                    </Link>

                                {/* </div> */}
                                <div className="FlexCenter">
                                    <div className="SectionDetails">
                                        <h2>Change Password</h2>
                                        <p>Please Create New Password.</p>
                                        <div className="FormBg">
                                            {/* <form> */}
                                            <div className="mb-3 mt-3">
                                                <label for="pwd" className="form-label">
                                                    Old Password
                                                </label>
                                                <div className="flex-password">
                                                    <input
                                                        // type='password'
                                                        type={!showpassw ? "password" : "text"}
                                                        className="form-control PasswordStyle"
                                                        id="pwd"
                                                        placeholder="Enter old password"
                                                        name="oldPassword"
                                                        value={oldPassword}
                                                        onKeyDown={handleKeyPress}
                                                        // onChange={(e) => setOldPassword(e.target.value)}
                                                    // value={email}
                                                    onChange={(e) => {
                                                        setOldPassword(e.target.value)
                                                        setSuccess(false) 
                                                        setError(false)
                                                        setErrorMessage2(false)
                                                        setErrorMessage3(false)
                                                        setErrorMessage4(false)
                                                      }}
                                                    />

                                                        <img src={img}

                                                            onClick={(e) => visiblePassw(e)}
                                                            alt="" className="passwordbtn"/>
                                                </div>
                                                {error && <div className="Error">{error}</div>}
                                                {error && oldPassword <= 0 ? <div className="Error">Please enter old password</div> : ""}
                                            </div>

                                            <div className="mb-3">
                                                <label for="pwd" className="form-label">
                                                    New Password
                                                </label>
                                                <div className="flex-password">
                                                    <input
                                                        // type='text'
                                                        type={!showpassw1 ? "password" : "text"}
                                                        className="form-control PasswordStyle"
                                                        id="pwd"
                                                        placeholder="Enter new password"
                                                        name="newPassword"
                                                        value={newPassword}
                                                        onKeyDown={handleKeyPress}
                                                        // onChange={(e) => setNewPassword(e.target.value)}
                                                    // value={email}
                                                    onChange={(e) => {
                                                        setNewPassword(e.target.value)
                                                        setError(false)   
                                                        // setError1(false)  
                                                        setSuccess(false)
                                                        setErrorMessage2(false)
                                                        setErrorMessage3(false)
                                                        setErrorMessage4(false)
                                                      }}
                                                    />

                                                        <img
                                                            src={img1}
                                                            onClick={(e) => visiblePassw1(e)}
                                                            alt="" className="passwordbtn" />
                                                </div>
                                                {errorMessage2 && <div className="Error">{errorMessage2}</div>}
                                                {error && newPassword <= 0 ? <div className="Error">Please enter new password</div> : ""}
                                            </div>
                                            <div className="mb-3">
                                                <label for="pwd" className="form-label">
                                                    Confirm Password
                                                </label>
                                                <div className="flex-password">
                                                    <input
                                                        // type='password'
                                                        type={!showpassw2 ? "password" : "text"}
                                                        className="form-control PasswordStyle"
                                                        id="pwd1"
                                                        placeholder="Confirm new password "
                                                        name="confirmPassword"
                                                        value={confirmPassword}
                                                        onKeyDown={handleKeyPress}
                                                        // onChange={(e) => setConfirmPassword(e.target.value)}
                                                        onChange={(e) => {
                                                            setConfirmPassword(e.target.value)
                                                            setError(false)  
                                                            // setError1(false)  
                                                            setSuccess(false) 
                                                            setErrorMessage2(false)
                                                            setErrorMessage3(false)
                                                            setErrorMessage4(false)
                                                          }}
                                                    />
                                                        <img
                                                            src={img2}
                                                            onClick={(e) => visiblePassw2(e)}
                                                            alt=""  className="passwordbtn"/>
                                                </div>
                                                {errorMessage3 && <div className="Error">{errorMessage3}</div>}
                                                {error && confirmPassword <= 0 ? <div className="Error">Please enter confirm password</div> : ""}  
                                                {errorMessage4 && <div className="Error">{errorMessage4}</div>}        
                                            </div>
                                            <div className="invalid-feedback">
                                            </div>
                                            {/* {success && (
                                                <div className="success">Password changed successfully</div>
                                            )} */}
                                            {/* {
                                                    pageState?.isError && <p>{pageState?.errorMessage}</p>
                                                } */}
                                            <button
                                                type="button"
                                                className="btn BtnDark mt-4"
                                                onClick={() => {
                                                    handleSubmit()
                                                }}
                                            >
                                                Save
                                            </button>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="RightSection">
                            <div className="ImageBox">
                                <img src={ImgLogin} alt="" />
                                <h3 className="Heading">The future of enforcement!!</h3>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    );
}




