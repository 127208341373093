import fetch from "isomorphic-fetch";
import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_RESPONSE_SECRET

async function detectWebRTCLeak() {
  return new Promise((resolve) => {
      let detectedIPs = new Set();
      let rtc = new RTCPeerConnection({ iceServers: [{ urls: "stun:stun.l.google.com:19302" }] });

      rtc.createDataChannel("");
      rtc.createOffer()
          .then((offer) => rtc.setLocalDescription(offer))
          .catch(console.error);

      rtc.onicecandidate = (event) => {
          if (!event || !event.candidate) return;

          let candidate = event.candidate.candidate;
          let ipRegex = /([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})/;
          let ipv6Regex = /([a-f0-9:]+:+[a-f0-9]+)/;

          let ipMatch = candidate.match(ipRegex) || candidate.match(ipv6Regex);
          if (ipMatch) {
              let ip = ipMatch[0];
              if (!detectedIPs.has(ip)) {
                  detectedIPs.add(ip);
              }
          }
      };

      setTimeout(() => resolve([...detectedIPs]), 2000); // Wait 2 seconds
  });
}

function getPublicIP(ipList) {
  return ipList.filter(ip => {
      // Regex to match valid IPv4 addresses
      return /^(\d{1,3}\.){3}\d{1,3}$/.test(ip);
  });
}


export const decryptResponse = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    return encryptedData; // Return original data if decryption fails
  }
};

export default async function fetchUrl(url, options = {}) {
  const jwtToken = localStorage.getItem("xC1hd");
  let authAddedOptions = options;

  if (typeof options !== "object") {
    authAddedOptions = {};
  }
  if (typeof authAddedOptions.headers !== "object") {
    authAddedOptions.headers = {};
  }
  if (jwtToken) {
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: "Bearer " + jwtToken,
    };
  }

  if (process.env.REACT_APP_RTC_ENABLED) {
    const ips = await detectWebRTCLeak();

    const publicIPs = getPublicIP(ips);
    console.log(Array.isArray(publicIPs), publicIPs?.length > 0);

    if (Array.isArray(publicIPs) && publicIPs.length > 0) {
        // req.headers['Access-Control-Allow-Origin'] = '*';
        authAddedOptions.headers['rtc'] = publicIPs[0];
    }
  }

  return fetch(url, authAddedOptions).then(async (res) => {

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const jsonResponse = await res.json();
    console.log("JSON Response:", jsonResponse);

    const decryptedData = jsonResponse?.encryptedData
      ? decryptResponse(jsonResponse.encryptedData)
      : jsonResponse;

    // ✅ Return a Fetch-like Response Object
    return {
      ...res, // Include original response properties (like `status`, `ok`, etc.)
      json: async () => decryptedData, // Provide `json()` method for compatibility
    };
  });
}
