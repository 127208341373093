import { fetch } from "../../utils";

const HostName = process.env.REACT_APP_API_HOSTNAME
export const logins = async (value) => {
  return fetch(`${HostName}/user/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const token = async (value) => {
  return fetch(`${HostName}/user/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const createoprtrsignup = async (value) => {
  return fetch(`${HostName}/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const forgotpassw = async (value) => {
  return fetch(`${HostName}/user/forgot`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const newpassword = async (value) => {
  return fetch(`${HostName}/user/${value.id}/${value.token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const lotprovider = async (value) => {
  // return fetch(`${HostName}/lot/provider?page=${value?.page}&size=${value?.size}&isPermit=${value?.isPermit}`,
  return fetch(`${HostName}/lot/provider?page=${value?.page}&size=${value?.size}&isPermit=${value?.isPermit}${value?.startDate ? `&start_date=${value?.startDate.toISOString()}` : ""}${value?.endDate ? `&end_date=${value?.endDate.toISOString()}` : ""}${value?.type ? `&type=${value?.type}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      },

    })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllLotsList = async (value) => {
  return fetch(`${HostName}/lot/admin/allLots?${value?.id ? `id=${value?.id}` : ""}${value?.role ? `&role=${value?.role}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      },

    })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateLotStatus = async (id, data) => {
  return fetch(`${HostName}/lot/status/update/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const updateThreshold = async (id, data) => {
  return fetch(`${HostName}/lot/threshold/update/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const updateGeoFence = async (id, data) => {
  return fetch(`${HostName}/lot/geofence/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const getEquipmentCharges = async (currentPage, size, query) => {
  return fetch(`${HostName}/payment/equipment-charge?page=${currentPage}&limit=${size}${query?.length > 0 ? `&query=${query}`: ""}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    }
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const user = async (value) => {
  return fetch(`${HostName}/user/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

////////////////////////////////////////////
export const updateUser = async (id, data) => {
  return fetch(`${HostName}/user/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};



////////////////////////////////////////////
// UPDATE PROFILE
/////////////////////////////////////////////////
export const editprofile = async (value) => {
  var formData = new FormData();
  formData.append('file', value?.user?.file)
  formData.append('fullname', value?.user?.fullname)
  formData.append('phone', value?.user?.phone)
  formData.append('email', value?.user?.email)
  formData.append('address', value?.user?.address)
  // formData.append('company', value?.user?.company)
  formData.append('isTaggrSurveyEnable', value?.user?.isTaggrSurveyEnable)
  formData.append('splits', JSON.stringify(value?.user?.splits))

  return fetch(`${HostName}/user/${value.userId}`, {
    method: "PUT",
    headers: {

      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: formData
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

//////////////////////////
export const deleteyourlot = async (id) => {
  return fetch(`${HostName}/lot/delete/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },

  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const addlots = async (value, id, isEdit) => {
  var formData = new FormData();
  formData.append('operator', value?.operator);
  formData.append('parkingLot', value?.parkingLot);
  formData.append('dropBox', value?.dropBox);
  formData.append('signage', value?.signage);
  formData.append('lotname', value?.lotname);
  formData.append('dropbox', value?.dropbox);
  formData.append('address', value?.address);
  formData.append('split_address', JSON.stringify(value?.split_address));
  formData.append('lotRules', value?.lotRules);
  formData.append('permitAllowed', value?.permitAllowed);
  formData.append('taggingType', JSON.stringify(value?.taggingType));
  formData.append('holdingFee', value?.holdingFee);
  formData.append('grace_period', value?.grace_period);
  formData.append('lot_space', value?.lot_space);
  formData.append('allowUnpaidCitations', value?.allowUnpaidCitations);
  formData.append('coordinates[lat]', value?.coordinates?.lat);
  formData.append('coordinates[lng]', value?.coordinates?.lng);
  if (value?.time_zone_name) {
    formData.append('time_zone[name]', value?.time_zone_name);
  }  
  formData.append('pmc_locationId', value?.pmc_locationId);
  formData.append('facilityId', value?.facilityId);
  formData.append('internalAccountingId', value?.internalAccountingId);
  formData.append('threshold', value?.threshold);
  formData.append('thresholdEmail', value?.thresholdEmail);
  formData.append('pro_park_lotId', value?.pro_park_lotId);

  if (value && value?.proof_images) {
    value?.proof_images.forEach(({ file, ImagePreview }) => {
      if (file || ImagePreview) {
        formData.append('proof_images', file || ImagePreview);
      }
    });
  }

  formData.append('paymentProviders', value?.paymentProviders);
  if (value?.offences) {
    formData.append('offences', value?.offences);
  }
  return fetch(isEdit ? `${HostName}/lot/${id}` : `${HostName}/lot`, {
    method: isEdit ? "PUT" : "POST",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
    body: formData
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getProviders = value => {
  return fetch(`${HostName}/payment-provider`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * get lots detail by its id
 */
export const getLotsById = id => {
  return fetch(`${HostName}/lot/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then(res => res.json())
    .then(payload => payload)
    .catch(error => { throw error });
};


/**
 * CREATE CONNECT ACCOUNT OF STRIPE
 */
export const connectWithStripe = async value => {
  return fetch(`${HostName}/connectToStripe?code=${value?.code}&userId=${value?.userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const connectWithStripeExpress = async value => {
  return fetch(`${HostName}/connectToStripe/express?userId=${value?.userId}&type=${value?.type}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

/***************************************************
 *              @section PERMIT                    *
 ***************************************************/

/**
 * CREATE PERMIT
 */
export const addPermit = async value => {
  return fetch(`${HostName}/permit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(value),
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};


/*
get permit api
*/

export const getPermitAll = async (isReport) => {
  return fetch(`${HostName}/permit/operator${isReport ? `?isReport=${true}` : ""}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const getPermit = async (page, lotNameFilter) => {
  return fetch(`${HostName}/permit/operator` + `${page ? `?page=${page}` : ""}` + `${lotNameFilter ? `&lotNameFilter=${lotNameFilter}` : ''}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// export const getPermit = async (page, isReport) => {
//   return fetch(`${HostName}/permit/operator${isReport ? "?isReport=true" : ""}${page ? `&page=${page}` : ""}`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "access-control-allow-origin": "*",
//       'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
//     },
//   })
//     .then(res => {
//       return res.json()
//     })
//     .then(payload => {
//       return payload
//     })
//     .catch(error => { throw error });
// };



/*
delete permit api
*/

export const deletePermit = async (id) => {
  return fetch(`${HostName}/permit/delete/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};
/*
get permit by id
*/

export const getPermitbyID = async (id, data) => {
  return fetch(`${HostName}/permit/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

/*
update permit api
*/

export const updatePermit = async (data) => {
  return fetch(`${HostName}/permit/update/${data.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data.data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const bulkUploadPermits = async (formData) => {
  return fetch(`${HostName}/permit/bulk_import`, {
    method: "POST",
    headers: {
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: formData
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const submitProof = async (value) => {
  var formData = new FormData();
  formData.append('proofImage', value?.proofImage)
  
  return fetch(`${HostName}/citation/proof?license_plate=${value?.licensePlate}&tag_serial=${value?.tireTagSerialNumber}&point_of_contact=${value?.point_of_contact}`, {
    method: "POST",
    headers: {
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: formData
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};



/***************************************************
 *             @section OFFENCE                    *
 ***************************************************/

/**
 * CREATE OFFENCE
 */
export const addOffence = async value => {
  return fetch(`${HostName}/offence`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(value),
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};


/**
 * GET OFFENCE BY OPERATOR
 */
export const getOffenceByOperator = async value => {
  console.log(value, "<<<<<valueskfdkfsdkfjsdk")
  return fetch(`${HostName}/offence/operator${value?.page || value?.size ? "?" : ""}${value?.page ? `page=${value?.page}` : ""}${value?.size && value?.page ? "&" : ""}${value?.size ? `size=${value?.size}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      },
    })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

/**
 * GET OFFENCE BY OPERATOR
 */
export const getActiveOffenceByOperator = async value => {
  return fetch(`${HostName}/offence/active/operator`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

/**
 * UPDATE OFFENCE 
 */
export const updateOffence = async (id, data) => {
  return fetch(`${HostName}/offence/update/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const getEnforcementDetails = async () => {
  return fetch(`https://us-central1-violations-managers.cloudfunctions.net/api/violations/reference-data`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'api-key': process.env.REACT_APP_ENFORCEMENT_REFERENCE_API,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}


/**
 * DELETE OFFENCE BY OPERATOR
 */
export const deleteOffence = async id => {
  return fetch(`${HostName}/offence/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};



/***************************************************
 *     @section CREATE PASSWORD                    *
 ***************************************************/


/**
 * GET EMAIL BY BUFFER
 */
export const getEmailByBuffer = async emailBuffer => {
  return fetch(`${HostName}/user/operator/email?email=${emailBuffer}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

/**
 * CREATE PASSWORD FOR A USER
 */
export const createPasswordForOperator = async (token, value) => {
  return fetch(`${HostName}/user/createpassword/${token}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
    body: JSON.stringify(value)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};


/*contact suport */

export const contactsupport = async value => {
  return fetch(`${HostName}/contact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(value),
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};


/***************************************************
 *     GET OPERATOS ON ADMIN DASHBOARD             *
 ***************************************************/



export const getusers = async (role, value) => {
  const url = `${HostName}/user/admin/users_list?role=${role}${value?.page ? `&page=${value?.page}` : ""}${value?.size ? `&size=${value?.size}` : ""}`;
  return fetch(url, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

/**************************************************************** 
 *     GET OPERATOS TAGGR NUMBER OF VISITS  ON ADMIN DASHBOARD  *
 ****************************************************************/

// export const get_operator_taggr_visit = async (role, value) => {
//   const url = `${HostName}/user/admin/users_visits?role=${role}${value?.page ? `&page=${value?.page}` : ""}${value?.size ? `&size=${value?.size}` : ""}`;
//   return fetch(url, {
//     method: "GET",
//     headers: {
//       'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
//       "access-control-allow-origin": "*"
//     },
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((payload) => {
//       return payload;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

export const get_operator_taggr_visit = async (role, value) => {
  const url = `${HostName}/user/admin/users_visits?role=${role}${value?.page ? `&page=${value?.page}` : ""}${value?.size ? `&size=${value?.size}` : ""}${value?.startDate ? `&start_date=${value?.startDate.toISOString()}` : ""}${value?.endDate ? `&end_date=${value?.endDate.toISOString()}` : ""}`;
  return fetch(url, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};



// *********************************
// GET USER DEATAIL ON ADMIN PAGE
// *********************************

export const getuserdetails = async (id, value) => {
  const url = `${HostName}/user/details/${id}`;
  // const url = `${HostName}/user/details/${id}?page=${value?.page}&size=${value?.size}`;
  return fetch(url, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};



export const getlotdetails = (id, value) => {
  // return fetch(`${HostName}/lot/admin/provider/${id}`
  // return fetch(`${HostName}/lot/admin/provider/${id}?page=${value?.page}&size=${value?.size}`, 
  const startDateParam = value?.startDate ? `&start_date=${value?.startDate.toISOString()}` : '';
  const endDateParam = value?.endDate ? `&end_date=${value?.endDate.toISOString()}` : '';
  
  return fetch(`${HostName}/lot/admin/provider/${id}?page=${value?.page}&size=${value?.size}${startDateParam}${endDateParam}`, 
  {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*"
      },
    })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};


// SEARCH API
export const searchForOperator = (searchType, query, citationType) => {
  return fetch(`${HostName}/search/operator?type=${searchType}&query=${query}&citation_type=${citationType}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}


export const gettaggrdetails = (id, value) => {
  const startDateParam = value?.startDate ? `&start_date=${value?.startDate.toISOString()}` : '';
  const endDateParam = value?.endDate ? `&end_date=${value?.endDate.toISOString()}` : '';
  // return fetch(`${HostName}/lot/admin/taggr/${id}?page=${value?.page}&size=${value?.size}$`,
  return fetch(`${HostName}/lot/admin/taggr/${id}?page=${value?.page}&size=${value?.size}${startDateParam}${endDateParam}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*"
      },
    })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// ALL CITATION [ADMIN DASHBOARD] //

export const allCitations = async (value) => {
  var date_url = value?.startDate ? `&start_date=${value?.startDate?.toISOString()}&end_date=${value?.endDate?.toISOString()}`: '';
  return fetch(`${HostName}/citation/admin/all?page=${value?.page}&size=${value?.size}&type=${value?.type}${date_url}&lotId=${value?.lotId}` +
    `&status=${value?.status}` + `&citation_type=${value?.citation_type}` + `&date_type=${value?.date_type}`
    , {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const searchallCitations = async (searchType, query, citationType) => {  
  return fetch(`${HostName}/search/operator?type=${searchType}&query=${query}&citation_type=${citationType}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}


// GET ALL TIRE TAGS FOR [CALL CENTER & ADMIN DASHBOARD] //
export const getAllTireTags = async () => {
  try{
    const response = await fetch(`${HostName}/tiretags/all`, {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
        "access-control-allow-origin": "*"
      },
    });
    const data = await response.json();
    return data;
  } catch(err) {
    console.log("Error while fetching tire tags!");
  }
};

///////////////
// GET SINGLE CITATION DETAILS
//////////////////

export const get_single_citation_details = async (citationId) => {
  const url = `${HostName}/citation/single_details?citationId=${citationId}`;
  return fetch(url, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};


// refund for a citation
export const refund_citation = async (citationId, reason,refund_role,taggrfee, amount) => {
  return fetch(`${HostName}/refund/citation?citationId=${citationId}&refundedBy=${refund_role}&holdTaggrFee=${taggrfee}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({reason, amount: Number(amount)})
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const refundEquipment = async (citationId, paymentId, reason, requestedAmount) => {
  return fetch(`${HostName}/refund/equipment`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({
      citationId, paymentId, reason, requestedAmount
    })
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// Schedule - lot
export const createSchedule = async (
  lotId,
  schedules,
  timezone
  ) => {
  return fetch(`${HostName}/lot/schedule/create`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({
      lotId,
      schedules,
      timezone
    })
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const getSchedule = async (
  lotId
  ) => {
  return fetch(`${HostName}/lot/schedule/get/${lotId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    }
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};



// Refund request create
export const refund_request = async (
  citationId,
  reason,
  requestType,
  requestedAmount,
  withholdTaggrFee
  ) => {
  return fetch(`${HostName}/refund-request/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({
      citationId,
      reason,
      requestType,
      requestedAmount,
      withholdTaggrFee
    })
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// GET ALL REFUND REQUEST
export const get_all_refund_requests = async (value) => {
  return fetch(`${HostName}/refund-request/getAll?page=${value?.page}&size=${value?.size}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    }
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// Update refund request status as approved
export const approved_refund_request = async (requestId, status) => {
  return fetch(`${HostName}/refund-request/updateStatus?requestId=${requestId}&status=${status}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    }
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// SEARCH API
export const search_refund_request = (searchType, query) => {
  return fetch(`${HostName}/search/operator?type=${searchType}&query=${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}

// refund for a citation
export const void_citation = async (citationId, reason, taggrfee, payment_source_id) => {
  return fetch(`${HostName}/void/citation?citationId=${citationId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({reason, payment_source_id, holdTaggrFee: taggrfee })
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// reduce citation
export const reduce_citation = async (citationId, reason) => {
  return fetch(`${HostName}/citation/reduce?citationId=${citationId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({reason})
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// deny citation
export const deny_citation = async (citationId, reason) => {
  return fetch(`${HostName}/citation/deny?citationId=${citationId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({reason})
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// send sms
export const send_sms = async (to,text) => {
  return fetch(`${HostName}/sms/send`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({
      to: to,
      from: process.env.REACT_APP_SMS_PHONE_NUM,
      text: text
    })
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// paid by cheque
export const paid_by_cheque = async ({chequeId, parkpliantReferenceId}) => {
  return fetch(`${HostName}/citation/mail_in/webhook`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify({
      data: [
        {
          referenceId: parkpliantReferenceId,
          chequeId: chequeId,
          frontend: true
        }
      ]
    })    
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// CITATION OPERATOR
export const operatoCitation = async (value) => {
  var date_url = value?.startDate ? `&start_date=${value?.startDate?.toISOString()}&end_date=${value?.endDate?.toISOString()}`: '';
  return fetch(`${HostName}/citation/operator/all?page=${value?.page}&size=${value?.size}&type=${value?.type}${date_url}&lotId=${value?.lotId}` +
    `&status=${value?.status}` + `&citation_type=${value?.citation_type}` + `&date_type=${value?.date_type}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const statistics_operator = async (lotId) => {
  return fetch(`${HostName}/statistics/operator?lotId=${lotId}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const statistics_admin = async () => {
  return fetch(`${HostName}/statistics/admin`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

//  RECONCILATION API 

export const reconcilation = async (role,value) => {
  const startDateParam = value?.startDate ? `&start_date=${value?.startDate.toISOString()}` : '';
  const endDateParam = value?.endDate ? `&end_date=${value?.endDate.toISOString()}` : '';
  return fetch(`${HostName}/statistics/old-payout?role=${role}${startDateParam}${endDateParam}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const current_payout = async (role, page, limit, value) => {
  const startDateParam = value?.startDate ? `&start_date=${value?.startDate.toISOString()}` : '';
  const endDateParam = value?.endDate ? `&end_date=${value?.endDate.toISOString()}` : '';
  const userId = value?.userId ? `&userId=${value?.userId}` : '';
  return fetch(`${HostName}/payout/current?role=${role}${startDateParam}${endDateParam}&page=${page}&limit=${limit}${userId}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const citation_reconcilation = async (role, page, limit,value) => {
  const startDateParam = value?.startDate ? `&start_date=${value?.startDate.toISOString()}` : '';
  const endDateParam = value?.endDate ? `&end_date=${value?.endDate.toISOString()}` : '';
  const type = value?.type ? `&type=${value?.type}` : '';
  return fetch(`${HostName}/statistics/payout-citations?role=${role}${type}${startDateParam}${endDateParam}&page=${page}&limit=${limit}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const payout_reconcilation = async (role, page, limit, value, userId) => {
  const startDateParam = value?.startDate ? `&start_date=${value?.startDate}` : '';
  const endDateParam = value?.endDate ? `&end_date=${value?.endDate}` : '';
  
  const userIdParam = userId ? `&userId=${userId}` : '';
  return fetch(`${HostName}/statistics/payout?role=${role}${startDateParam}${endDateParam}&page=${page}&limit=${limit}${userIdParam}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const get_payout_dates = async () => {
  return fetch(`${HostName}/statistics/payout-dates`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// UPDATE CITATION/NOTICES

export const update_citation_violation = async (citationId,offenceId) => {
  return fetch(`${HostName}/citation/single_update?citationId=${citationId}&offenceId=${offenceId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    // body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// GET ALL DISPUTED CITATIONS
export const get_all_disputed_citations = async ({ page, limit, filter }) => {
  return fetch(`${HostName}/dispute-chatbot/disputed-citations/all?page=${page || 1}&limit=${limit || 10}&operator=${filter?.operator || ""}&lotId=${filter?.lotId || ""}&startDate=${filter?.startDate || ""}&endDate=${filter?.endDate || ""}&status=${filter?.status || ""}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    // body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// GET DISPUTED CITATION BY ID
export const get_disputed_citation_by_id = async (id) => {
  return fetch(`${HostName}/dispute-chatbot/disputed-citations/get?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// SEARCH DISPUTED CITATIONS
export const search_disputed_citations = async (query, filter) => {
  return fetch(`${HostName}/dispute-chatbot/disputed-citations/search?query=${query || ""}&filter=${filter || ""}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd")
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}

// GET ALL DISPUTED CITATIONS OPERATOR
export const get_all_disputed_citations_operator = async ({ page, limit }) => {
  return fetch(`${HostName}/dispute-chatbot/disputed-citations/operator/all?page=${page || 1}&limit=${limit || 10}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    // body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// GET DISPUTED CITATION BY ID OPERATOR
export const get_disputed_citation_by_id_operator = async (id) => {
  return fetch(`${HostName}/dispute-chatbot/disputed-citations/operator/get?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// SEARCH DISPUTED CITATIONS OPERATOR
export const search_disputed_citations_operator = async (query) => {
  return fetch(`${HostName}/dispute-chatbot/disputed-citations/operator/search?query=${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd")
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}


export const getTaggrActivities = async (value) => {
  return fetch(`${HostName}/taggr-activities?page=${value?.page}&size=${value?.size}${value?.lotId ? `&lotId=${value?.lotId}` : ""}${value?.startDate ? `&start_date=${value?.startDate.toISOString()}` : ""}${value?.endDate ? `&end_date=${value?.endDate.toISOString()}` : ""}`,
   {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd")
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}


/**
 * CREATE MANAGER POST REQUEST WITH 🚀 OPERATOR'S TOKEN 🚀
 */
export const addManager = async value => {
  return fetch(`${HostName}/user/manager/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(value),
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// GET ALL MANAGERS WITH LOT ADDRESS FOR OPERATOR DASHBOARD MANAGER SECTION
export const getAllManagers = async ({ page, limit }) => {
  return fetch(`${HostName}/user/manager/get?page=${page || 1}&limit=${limit || 10}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    }
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const getManagerById = async (managerId) => {
  return fetch(`${HostName}/user/manager/get/${managerId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    }
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const getPaymentReceipt = async (citationId, receiptType = "payment") => {
  return fetch(`${HostName}/payment/receipt?citationId=${citationId}&receiptType=${receiptType}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    }
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const updateManager = async (id, data) => {
  return fetch(`${HostName}/user/manager/update/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// zones
export const getAllZones = async (value) => {
  return fetch(`${HostName}/lot/admin/all_zones`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      }
    })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getZoneInventory = async (zoneId) => {
  return fetch(`${HostName}/lot/inventory/zone/${zoneId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      }
    })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

// CREATE ROLE

export const addRole = async value => {
 return fetch(`${HostName}/role/create`, {
   method: "POST",
   headers: {
     "Content-Type": "application/json",
     "access-control-allow-origin": "*",
     'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
   },
   body: JSON.stringify(value),
 })
   .then(res => {
     return res.json()
   })
   .then(payload => {
     return payload
   })
   .catch(error => { throw error });
};

export const getRoleByOperator = async value => {
  return fetch(`${HostName}/role/getByOperator${value?.page || value?.size ? "?" : ""}${value?.page ? `page=${value?.page}` : ""}${value?.size && value?.page ? "&" : ""}${value?.size ? `size=${value?.size}` : ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      },
    })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const deleteRole = async id => {
  return fetch(`${HostName}/role/deleteRole/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};


export const updateRole = async (id, data) => {
  return fetch(`${HostName}/role/update/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// discord webhooks
export const getAllLotsAndWebhooks = async () => {
  return fetch(`${HostName}/discord-webhook`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
      },
    })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const createOrUpdateWebhook = async (data) => {
  return fetch(`${HostName}/discord-webhook`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem("xC1hd"),
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};
