import React, { useReducer, useRef } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Pagination, Tooltip } from 'antd';
import searchicon from "../../../images/search.svg";
import location from "../../../../src/images/location.svg";
import cpma from "../../../images/cpma.svg";
import View from "../../../images/IC-view.svg";
import Loading from "../../../utils/loading/Loading";
import DarkView from "../../../images/DarkViewImg.svg";
import WhiteView from "../../../images/WhiteViewImg.svg";

import leftArrow from "../../../images/left-arrow.svg";
import Header from "../Citations-Header/container";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { useDispatch } from "react-redux";
import { capitalize, debounce, getDaysDifference } from "../../../global";
import moment from "moment-timezone";
import Confirm_Modal from '../../../utils/modal'
import Refund_modal from "../../../utils/refund_modal";
import TextModal from "../../../utils/text_modal";
import { CSVLink } from "react-csv";
import Download from "../../../components/svgIcons/Download";
import "./styles.scss";
import { DataGrid } from "@mui/x-data-grid";
import { REQUEST_TYPES } from "../../../constants";
import { Box } from "@mui/material";
import ClearDates from "../../../utils/clearDates/ClearDates";
import PayByCheque from "../../../utils/pay_by_cheque_modal";

export default function AdminCitationsComponent(props) {

  const tz = moment.tz?.guess()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [reason, setReason] = useState('')
  const [showTextModal, setShowTextModal] = useState(false);
  const [showPBCModal, setShowPBCModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [chequeId, setChequeId] = useState("");
  const [parkpliantReferenceId, setParkpliantReferenceId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [csvData, setCsvData] = useState();
  const [role, setrole] = useState()
  const [lots, setLots] = useState([]);
  const getLocalStorageValue = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  const selectedLot = getLocalStorageValue("selectedLot", "All");
  const selectedStatus = getLocalStorageValue("selectedStatus", "All");
  const selectedCitation_type = getLocalStorageValue("selectedCitation_type", "All");
  const selectedDate_type = getLocalStorageValue("selectedDate_type", "createdAt");

  const [searchKey, setSearchKey] = React.useState(() => {
    const searchKey = localStorage.getItem('searchKey');
    return searchKey
      ? JSON.parse(searchKey)
      : '';
  });
  const searchInputRef = useRef()

  const setLocalStorageValue = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const setSelectedLot = (value) => {
      setLocalStorageValue("selectedLot", value);
      // Add any additional logic for updating UI or fetching data
  };

  const setSelectedStatus = (value) => {
      setLocalStorageValue("selectedStatus", value);
  };

  const setSelectedCitation_type = (value) => {
      setLocalStorageValue("selectedCitation_type", value);
  };

  const setSelectedDate_type = (value) => {
      setLocalStorageValue("selectedDate_type", value);
  };

  useEffect(() => {
    if (!searchKey.length > 2) {
     allCitationsinfo(1, {lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type});
    }
    // handleDateSubmit();
    fetchName()
  }, [selectedLot, selectedStatus, selectedCitation_type, selectedDate_type]);

  const fetchName = async () => {
    // dispatch(setLoading(true))
    try {
      const { value } = await props.user();
      setrole(value?.user?.role)
      getLotList(value?.user?._id, value?.user?.role);
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  };

  const getLotList = async (id, role) => {
    const {value} = await props.getAllLotsList({id, role});
    console.log(value, "<<--lots")
    if (value?.lots) {
      setLots(value?.lots);
    }
  };

  // console.log("role", role);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(() => {
    const storedVisibilityModel = localStorage.getItem('columnVisibilityModel');
    return storedVisibilityModel
      ? JSON.parse(storedVisibilityModel)
      : {
          tiretag_citation_data: true,
          lotdata: true,
          tireTagSerialNumber: true,
          vehicle: true,
          citation_type: true,
          internalAccountingId: true,
          payment_status: true,
          payment_date: true,
          "taggr break_down_amount": true,
          "operator break_down_amount": true,
          "TireTag amount break_down_amount": true,
          "Taggr Solutions Amount break_down_amount": true,
          break_down_amount: true,
          reduceTo: true,
          reduce_reason: true,
          amountBeforeReductionFromAnubis: true,
          amountAfterReductionFromAnubis: true,
          confidenceLevelAnubis: true,
          taggr: true,
          issued: true,
          b: true,
        };
  });

  useEffect(() => {
    localStorage.setItem('columnVisibilityModel', JSON.stringify(columnVisibilityModel));
  }, [columnVisibilityModel]);


  useEffect(() => {
    localStorage.setItem('searchKey', JSON.stringify(searchKey));
  
    if (searchKey.length > 2) {
      search(searchKey);
    } else if (searchKey.length === 0) {
      allCitationsinfo(1, {lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type});
    }
  }, [searchKey]);
  

  const noticeFields = [
    "Tag Type",
    "Notice Number",
    "Lot",
    "Tag Serial Number",
    "Internal Accounting Id",
    "Vehicle Plate",
    "State",
    "Make",
    "Status",
    "Notice Amount",
    "Amount",
    "Payment Date",
    "Issued By",
    `Notice Issue Date (${moment().tz(tz).zoneName()})`,
    "Notice Issue Date (UTC)",
    "Taggr Amount",
    "Operator Amount",
    "TireTag Amount",
    "Taggr Solutions Amount",
    "Dock Fees",
    "Total Stripe Fees/Surcharged",
    "Call Center Fees"
  ];

  const isPaymentDisabled = (payment_status) => {
    ["paid", "voided", "refunded"].includes(payment_status);
  }

  const columns =[
    { 
      field: "tiretag_citation_data",
      headerName: "Notice Number",
      width: 200,
      hideable: false,
      editable: false,
      filterable: false,
      sortable: false,
      valueFormatter: (val, row) => `${row?.citation_type === "mail_in"
      ? row?.citation_mail_in_number?.replace("MAIL_IN_", " ") 
      : row?.citation_type === "tag_vehicle" 
        ? row?.tiretag_citation_data?.citation_number 
        : (row?.qr_code ?? row?.citation_number ?? '')}`,
    },
    {
      field: "lotdata",
      headerName: "Lot",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val) => (val?.address),
    },
    {
      field: "tireTagSerialNumber",
      headerName: "Tag Serial Number",
      width: 150,
    },
    {
      field: "vehicle",
      headerName: "Vehicle Plate",
      width: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        params.value?.correctedLP ? (
          <Tooltip
            title={
              <Box
                sx={{
                  backgroundColor: 'green',
                  padding: '0.5rem',
                  color: 'white',
                  borderRadius: '0.25rem',
                }}
              >
                {params.value.correctedLP}
              </Box>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'green',
                },
              },
            }}
          >
            <Box
              component="span"
              sx={{
                color: 'red',
                fontWeight: 'bold',
                cursor: 'pointer'
              }}
            >
              {params.value.licensePlate}
            </Box>
          </Tooltip>
        ) : (
          <Box
            component="span"
            sx={{
              color: 'inherit',
              fontWeight: 'normal',
            }}
          >
            {params.value?.licensePlate}
          </Box>
        )
      ),
    },
    { field: "citation_type", headerName: "Tag Type", width: 150},
    { 
      field: "internalAccountingId",
      headerName: "Internal Accounting Id",
      width: 150,
      valueGetter: (val, row) => row?.lotdata?.internalAccountingId
    },
    {
      field: "payment_status",
      headerName: "Status",
      width: 180,
      renderCell: (row) => {
        let val = row?.row;
        console.log(val?.refund, "<<<value")
        return  <td align="center">
                  <span className={status_classes[val?.payment_status] || 'red'}>
                    {capitalize(val?.refund?.length > 0 && val?.refund[0]?.refund_type === "partial" ? "Partial Refund" : val?.payment_status)}
                  </span>
                </td>
      },
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val) => capitalize(val)
    },
    {
      field: "day_without_payment",
      headerName: "Days Without Payment",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val, row) => getDaysDifference(row?.createdAt, row?.payment_date)
    },
    {
      field: "taggr break_down_amount",
      headerName: "Taggr amount",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val, row) => `${(
        (row?.citation_type === "tag_vehicle" && row?.payment_status === "pending"
        || (row?.payment_status === "voided" && row?.holdTaggrFee ) ? 0 : row?.break_down_amount?.taggr?.amount ?? 0))}`

    },
    {
      field: "operator break_down_amount",
      headerName: "operator amount",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val, row) =>  `${((row?.payment_status === "pending" || row?.payment_status === "voided" ? 0 : row?.break_down_amount?.operator?.amount ?? 0))}`

    },
    {
      field: "TireTag amount break_down_amount",
      headerName: "TireTag amount",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val, row) =>  `${((row?.payment_status === "pending" || row?.payment_status === "voided" ? 0 : row?.break_down_amount?.tire_tag?.amount ?? 0))}`

    },
    {
      field: "Taggr Solutions Amount break_down_amount",
      headerName: "Taggr Solutions Amount",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val, row) =>  `${((row?.payment_status === "pending" || row?.payment_status === "voided" ? 0 : row?.break_down_amount?.taggr_solutions?.amount ?? 0))}`

    },
    {
        field: "calculative_amount",
        headerName: "Notice Amount",
        width: 150,
        filterable: false,
        sortable: false,
        valueFormatter: (val, row) =>  `${(row?.break_down_amount?.calculative_amount ?? 0)?.toFixed(2)}`
    },
    {
        field: "break_down_amount",
        headerName: "Amount",
        width: 150,
        filterable: false,
        sortable: false,
        valueFormatter: (val) =>  `${((val?.calculative_amount ?? 0) + (val?.stripe_fees_offence ?? 0) + (val?.dock_fees ?? 0) + (val?.late_fee ?? 0))?.toFixed(2)}`

    },
    {
      field: "reduceTo",
      headerName: "Reduced Amount",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val) =>  (val?.reduceTo || 0)
    },
    {
      field: "reduce_reason",
      headerName: "Reduce Reason",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val) =>  (val?.reduce_reason || "-")
    },
    {
      field: "amountBeforeReductionFromAnubis",
      headerName: "Amount Before Reduction From Anubis",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val) =>  val?.amountBeforeReductionFromAnubis?.toFixed(2)
    },
    {
      field: "amountAfterReductionFromAnubis",
      headerName: "Amount After Reduction From Anubis",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val) =>  val?.amountAfterReductionFromAnubis?.toFixed(2)
    },
    {
      field: "confidenceLevelAnubis",
      headerName: "Anubis Confidence Level (%)",
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (val) =>  val?.confidenceLevelAnubis
    },
    { field: "taggr", headerName: "Issued By", width: 150, filterable: false,
      sortable: false,
      valueFormatter: (val) => val?.fullname ?? "-" },
    { field: "issued", headerName: `Issued Local Time (${moment().tz(tz).zoneName()})`, width: 250, filterable: false,
      sortable: false,
      valueFormatter: (val, row) => {
        var date = row?.tiretag_citation_data?.created_at
        var mail_in_citation_date = row?.createdAt
        return moment(date ? date :mail_in_citation_date)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')}
     },
    { field: "a", headerName: `Issued UTC Time`, width: 250, filterable: false,
      sortable: false,
      valueFormatter: (val, row) => {
        var date = row?.tiretag_citation_data?.created_at
        var mail_in_citation_date = row?.createdAt
        return moment(date ? date :mail_in_citation_date)?.tz('UTC')?.format('YYYY-MM-DD HH:mm:ss')}
     },
     { field: "b", headerName: "Actions", width: 400, height: 400,  renderCell: (row) => 
      {
        var val = row?.row;
        return  <div className="d-flex align-items-center">
        { role === "admin" || role === "superAdmin" 
          && (
            val?.payment_status !== "pending" && val?.payment_status !== 'voided' 
            && val?.citation_type !== "mail_in"
            ? <button
                className="DarkBlackBtn"
                style={{width: "30px"}}
                onClick={() => {
                  const url = `/admin/receipt/${val?._id}?type=${val?.citation_type}`;
                  const fullUrl = `${window?.location?.origin}${url}`
                  window.open(fullUrl, "_blank");
                }}
              >
                🖨️
              </button>
            : <button
                className="DarkEditBtnDisbled"
                style={{width: "30px"}}
                disabled
              >
                🖨️
              </button>
          )
        }
          <Link
            to={`/citationsInformation/${val?._id}`}
            className="eyeBtn DarkBlackBtn"
          >
            <img src={DarkView} className="DarkImg" />
            <img src={WhiteView} className="WhiteImg" />
          </Link>

          {/* <button  className={val?.payment_status === "voided" ? "DarkEditBtnDisbled" : "DarkEditBtn"}  */}
          <button
            className="DarkEditBtnDisbled DarkBlackBtn"
            style={val?.refund_request?.requestType === REQUEST_TYPES.VOID_REQUEST ? {width: "100px"}: {}}
            onClick={() => {
              if (val?.payment_status === "voided") {
                dispatch(
                  setAlert(
                    "info",
                    "Notice already voided."
                  )
                );
              } else if (
                val?.payment_status !== "paid" &&
                val?.payment_status !== "refunded" &&
                !val?.refund_request?._id
              ) {
                setPageState({
                  void_citation_id: val?._id,
                  show_void_modal: true,
                  citation_type: val?.citation_type,
                  void_popup_title: val?.citation_type === "tag_vehicle"
                    ? val?.tiretag_citation_data?.citation_number
                    : val?.citation_type === "taggrNotice"
                      ? val?.citation_number 
                      : val?.citation_mail_in_number?.replace("MAIL_IN_", " "),
                  amount: (
                    val?.break_down_amount
                      ?.calculative_amount +
                    val?.break_down_amount
                      ?.stripe_fees_offence
                  )?.toFixed(2),
                });
              } else {
                dispatch(
                  setAlert(
                    "info",
                    "You can't void a Notice after it's paid!"
                  )
                );
              }
            }}
            disabled={(val?.payment_status !== "pending" || val?.refund_request?._id)}
          >
            {val?.payment_status === "voided"
              ? "Voided"
              : (val?.refund_request?.requestType === REQUEST_TYPES.VOID_REQUEST ? "Requested" : "Void")}
          </button>

          {val?.payment_status === "paid" ||
          val?.payment_status === "voided" ||
          val?.payment_status === "refunded" ? (
            <button
              disabled={
                val?.payment_status === "paid" ||
                val?.payment_status === "voided" ||
                val?.payment_status === "refunded"
              }
              className="DarkEditBtnDisbled"
            >
              Edit
            </button>
          ) : (
            <Link
              to={`/Edit_citationsInformation/${val?._id}`}
              className="DarkBlackBtn"
            >
              Edit
            </Link>
          )}
          {val?.payment_status === "paid" ||
          val?.payment_status === "voided" ||
          val?.payment_status === "refunded" || 
          val?.citation_type === "taggrNotice" ? (
            <button
              disabled={
                val?.payment_status === "paid" ||
                val?.payment_status === "voided" ||
                val?.payment_status === "refunded" ||
                 val?.citation_type === "taggrNotice"
              }
              className="DarkEditBtnDisbled"
            >
              Pay
            </button>
          ) : (
            <a
              className="DarkBlackBtn"
              href={`${
                process.env.REACT_APP_HOST ===
                "DEVELOPMENT"
                  ? "http://localhost:3002"
                  : "https://paytaggr.com"
              }?serialNumber=${
                val?.tireTagSerialNumber
              }&licensePlate=${
                val?.vehicle?.licensePlate
              }&payment_by=${
                role === "superAdmin"
                  ? "admin"
                  : role === "callcenter"
                  ? "call_center"
                  : ""
              }`}
            >
              Pay
            </a>
          )}

        {/* <button className="DarkGreenBtn">
            Pay
          </button> */}

          {val?.payment_status === "pending" ? (
            !val.proof ? <button className="DarkBlackBtn" 
            style={{width: "65px"}} onClick={toggleTextModal}>
              Text
            </button>
            :
            <button
              className="proof-button DarkBlackBtn"
              style={{width: "65px"}}
              onClick={() => {
                window.location.href = `/citationsInformation/${val?._id}?is_proof=true`;
              }}
            >
              Proof
            </button>
          ) : (val?.payment_status === "pending" ||
          val?.payment_status === "voided"||
          val?.payment_status === "refunded" || 
          val?.citation_type === "taggrNotice" ||
          val?.refund_request?._id
          ) && role !== "callcenter" ? (
          <button
            disabled={
              val?.payment_status === "pending" ||
              val?.payment_status === "voided"||
              val?.payment_status === "refunded" ||
              val?.citation_type === "taggrNotice" ||
              val?.refund_request?._id
            }
            className="RefundBtnDisbled DarkBlackBtn"
            onClick={() =>
              {setPageState({
                refund_citation_id: val?._id,
                operator_name : val?.lotdata?.operator_fullname,
                refund_popup_title : val?.tiretag_citation_data?.citation_number,
                showModal: true,
              })
            console.log(val?._id);
            }
            }
            style={{width: "65px"}}
          >
          {val?.refund_request?.requestType === REQUEST_TYPES.REFUND_REQUEST ? "Requested" : "Refund"}
          </button>
        ) : role !== "callcenter" && (
          <button
            className="DarkBlackBtn"
            style={{width: "65px"}}
            onClick={() =>
              setPageState({
                refund_citation_id: val?._id,
                operator_name : val?.lotdata?.operator_fullname,
                refund_popup_title : val?.tiretag_citation_data?.citation_number,
                showModal: true,
                  amount: (
                  val?.break_down_amount
                    ?.calculative_amount +
                  val?.break_down_amount
                    ?.stripe_fees_offence + 
                    (val?.break_down_amount?.dock_fees ?? 0)
                )?.toFixed(2),
                maximumRefundAmount: val?.break_down_amount?.calculative_amount > 55 ? (val?.break_down_amount?.calculative_amount - 55)?.toFixed(2) : 0,
                fullRefundAmount:  (
                  val?.break_down_amount
                    ?.calculative_amount +
                  val?.break_down_amount
                    ?.stripe_fees_offence + 
                    (val?.break_down_amount?.dock_fees ?? 0)
                )?.toFixed(2)
              })
            }
          >
            Refund
          </button>
        )}
        {/* <button className="DarkBlackBtn" onClick={() => setPageState({
                refund_citation_id: val?._id,
                showModal: true
              })}>
            Refund
          </button> */}
        </div>
        }
      },
      { field: "by", headerName: "More Actions", width: 150, height: 400,  renderCell: (row) => 
        {
          var val = row?.row;
          {/* pay by cheque  */}

          return <div>
            {val?.payment_status === "paid" ||
            val?.payment_status === "voided" ||
            val?.payment_status === "refunded" || val?.citation_type !== "mail_in" ? (
              <button
                disabled
                className="DarkEditBtnDisbledPayByCheck"
              >
                Pay by cheque
              </button>
            ) : (
              <button
                className="DarkBlackBtnPayByCheck"
                onClick={() => {
                  togglePBCModal();
                  setParkpliantReferenceId(val?.parkpliant_reference_id);
                }}
              >
                Pay by cheque
              </button>
            )}
          </div>
        }
      }
  ];

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10,
    showModal: false,
    refund_citation_id: null,
    refund_popup_title: '',
    operator_name : '',
    amount: 0,
    citation_type: null,
    isSearched: false
  }
  const status_classes ={
    paid: "paid-green",
    pending: "red",
    voided: "voided-red"
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      allCitationsinfo(next?.currentPage, {lotId: selectedLot})
    }
    return { ...prev, ...next }
  }, initialPageState)

  const [data, setdata] = useState();

  // const [Operatorname, setOperatorname] = useState();
  // console.log(pageState?.operatorName,"-----555555------>>>>");

  async function allCitationsinfo(page, {dispatched, lotId, status, citation_type, date_type}) {
    if(dispatched)
      {} else{dispatch(setLoading(true))}
    try {
      const { value } = await props.allCitations({ page, size: 10, startDate: startDate, endDate: endDate,
        lotId: lotId ?? selectedLot,
        status: status ?? selectedStatus,
        citation_type: citation_type ?? selectedCitation_type,
        date_type: date_type ?? selectedDate_type,
      })
      if (value?.success) {
        setdata(value)
        console.log(value, "000000000000");
        setPageState({ total: value?.total, isSearched: false })
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    fetchName()
    // dispatch(setLoading(false))
  }

  async function handleDateSubmit() {
    console.log(startDate, endDate, startDate && !endDate, "<==condition")
    if(!startDate && !endDate){
      return 
    }
    if (startDate && !endDate) {
      dispatch(setAlert('error', "Please select end date"))
      console.log("Please select start and end date");
      return;
    }
    dispatch(setLoading(true))
    try {
      const { value } = await props.allCitations({ page: 0, startDate: startDate, endDate: endDate,
        lotId: selectedLot,
        status: selectedStatus,
        citation_type: selectedCitation_type,
        date_type: selectedDate_type,
      })

      setCsvData(value)
      await allCitationsinfo(1, {dispatched: true, lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type});
      setPageState({ currentPage: 1 })
    } catch (error) {
      // dispatch(setAlert("error", error?.message))
    }
    setTimeout(() => {
        dispatch(setLoading(false))
      }, 300);
  }

  async function clearDates() {
    setStartDate(null)
    setEndDate(null)
    dispatch(setLoading(true))
    try {
     setCsvData([])
     await allCitationsinfo(1, {dispatched: true, lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type});
     setPageState({ currentPage: 1 })
    } catch (error) {
      // dispatch(setAlert("error", error?.message))
    }
    setTimeout(() => {
        dispatch(setLoading(false))
      }, 300);
  }

  const handleModalClose = () => {
    setReason('');
    setrefund_role("admin");
    setTaggrFee(false);
    setPageState({ showModal: false });
  };


  const [refund_role, setrefund_role] = useState();
  const [taggrFee, setTaggrFee] = useState(false);


  console.log(refund_role,"refund_role------->>>>");
  // console.log(taggrFee,"taggrFee------->>>>");

  const onDeleteModalSubmit = async () => {

    // console.log(taggrFee,"000000000000000000");

    // dispatch(setLoading(true))
    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }
    if(pageState?.amount < 1){
      err = true;
      dispatch(setAlert("error", "Invalid amount entered!"));
    }
    // Amount must be greater equal to 55 amount
    if (parseFloat(pageState?.amount) > parseFloat(pageState?.maximumRefundAmount) && pageState?.amount !== pageState?.fullRefundAmount) {
      err = true
      dispatch(setAlert('error', "Invalid amount entered!"))
    }

    if (refund_role === undefined) {
      err = true
      dispatch(setAlert('error', "Please select Issued by!"))
    }

    if (!err) {
      setPageState({ showModal: false })

      try {
        const { value } = await props.refund_citation(pageState?.refund_citation_id, reason,refund_role,taggrFee, pageState?.amount);
        if (value?.success) {
          allCitationsinfo(1, {lotId: selectedLot});
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
      handleModalClose()
    }
    dispatch(setLoading(false))
  };

  const on_void_citation_modal_submit = async (payment_source_id) => {
    dispatch(setLoading(true))
    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }
    if (!err) {
      setPageState({ show_void_modal: false })

      try {
        const { value } = await props.void_citation(pageState?.void_citation_id, reason, taggrFee, payment_source_id );
        if (value?.success) {
          allCitationsinfo(1, {lotId: selectedLot});
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("erro", error?.message))
      }
      setReason('')
      setTaggrFee(false)
    }
    dispatch(setLoading(false))
  };


  const search = async query => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchallCitations('citation_admin', query)
        if (value?.success) {
          setdata({ citation: value?.results })
          setPageState({ total: value?.total, isSearched: true })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  // const navigateToPaymentPage = () => {
  //   window.open('http://localhost:3002/citations/payment/callcenter', '_blank');
  // }

  const toggleTextModal = () => {
    setShowTextModal(!showTextModal);
  }
  const togglePBCModal = () => {
    setShowPBCModal(!showPBCModal);
  }

  const handleTextSend = async () =>{
    let err
    if (!phoneNumber) {
      err = true
      dispatch(setAlert('error', "Phone number cant be empty"))
    }
    dispatch(setLoading(true))
    if (!err) {
      const phoneNumberForLink = encodeURIComponent(phoneNumber);
      try {
        const { value } = await props.send_sms(phoneNumber, `${process.env.REACT_APP_CHATBOT_LINK}?phone_number=${phoneNumberForLink}`);
        if (value?.success) {
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
    }
    setShowTextModal(!showTextModal);
    dispatch(setLoading(false))
}

const handlePBC = async () =>{
  let err
  if (!chequeId) {
    err = true
    dispatch(setAlert('error', "cheque Id can't be empty"))
  }
  dispatch(setLoading(true))
  if (!err) {
    try {
      const { value } = await props.paid_by_cheque({chequeId, parkpliantReferenceId });
      if (value?.success) {
        dispatch(setAlert("success", value?.message))
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
  }
  setShowPBCModal(!showPBCModal);
  allCitationsinfo(1, {lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type});
  dispatch(setLoading(false))
}

  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              {
                role !== "callcenter" ?
                  <img src={leftArrow} className="left-arrow " onClick={() => navigate(-1)} />
                  : ""
              }
              <h4>Tags Deployed</h4>
              </div>
              <div className="admin-citation-page">
              <div className="date-picker-block mb-4 mt-4" style={{marginLeft: "5%"}}>
                <div className="d-flex align-items-center border-block">
                    <div className="date-picker-list">
                        <label>Start Date</label>
                        <DatePicker
                            selected={startDate}
                            // onChange={(date) => setStartDate(date)}
                            onChange={(date) =>
                                setStartDate(moment(date).tz(tz).hour(6).minute(0).toDate())
                            }
                            placeholderText="--/--/----"
                            className="date-picker"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="date-picker-list">
                        <label>End Date</label>
                        <DatePicker
                            selected={endDate}                                             
                            // onChange={(date) =>
                            //     setendDate(moment(date).tz(tz).toDate())
                            // }
                            onChange={(date) => {
                                const newDate = moment(date).tz(tz);
                                newDate.set({ hour: 23, minute: 59, second: 59 });
                                setEndDate(newDate.toDate());
                            }}                                                
                            placeholderText="--/--/----"
                            className="date-picker"
                            minDate={startDate}
                            maxDate={new Date()}
                            disabled={!startDate}
                        />
                    </div>
                </div>
                <div className="operator-btns">
                  <div className="border-block-btn">
                    <button className="btn DarkBtn" onClick={handleDateSubmit} >Submit</button>
                  </div>
                </div>
                  <ClearDates 
                  onClick={clearDates}
                  className="me-2"/>
                {csvData?.citation?.length > 0 ? (<CSVLink
                data={[
                  noticeFields, // header row
                  ...(csvData?.citation?.map((p) => [
                      p?.citation_type,
                      p?.citation_type !== "mail_in" ? p?.qr_code ?? p?.tiretag_citation_data?.citation_number ?? p?.citation_number : p?.parkpliant_reference_id,
                      p?.lotdata?.address,
                      p?.citation_type === "tag_vehicle" ? p?.tireTagSerialNumber : "-",
                      p?.lotdata?.internalAccountingId,
                      p?.vehicle?.licensePlate,
                      p?.state,
                      p?.vehicle?.make,
                      p?.refund?.length > 0 && p?.refund[0]?.refund_type === "partial" ? "Partial Refund" : p?.payment_status,
                      p?.break_down_amount?.calculative_amount,
                      (p?.break_down_amount?.calculative_amount ?? 0) + (p?.break_down_amount?.stripe_fees_offence ?? 0) + (p?.break_down_amount?.dock_fees ?? 0) + (p?.break_down_amount?.late_fee ?? 0),
                      p?.payment_date,
                      p?.taggr?.fullname,
                      moment(p?.createdAt)?.tz(tz).format('YYYY-MM-DD HH:mm:ss'),
                      moment(p?.createdAt)?.tz('UTC')?.format('YYYY-MM-DD HH:mm:ss'),
                      (p?.citation_type === "tag_vehicle" && p?.payment_status === "pending"
                        || (p?.payment_status === "voided" && p?.holdTaggrFee )) ? 0 : p?.break_down_amount?.taggr?.amount,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 : p?.break_down_amount?.operator?.amount,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 :  p?.break_down_amount?.tire_tag?.amount,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 : p?.break_down_amount?.taggr_solutions?.amount,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 : p?.break_down_amount?.dock_fees,
                      p?.payment_status === "pending" || p?.payment_status === "voided" ? 0 : p?.break_down_amount?.total_stripe_fees,
                      p?.break_down_amount?.service_fee,
                    ]
                  ) || []) // map data to rows
                ]}
                filename={"TagsDeployed.csv"}
                className="btn DarkBtn download-btn"
                style={{height: "fit-content"}}
              >
                <Download/>
                  Download
              </CSVLink>
              ) : (
                <button
                  className="btn DarkBtn download-btn"
                  style={{height: "fit-content"}}
                  onClick={(e) => e.preventDefault()} // prevents any action
                  disabled
                >
                  <Download color="black"/>
                  Download
                </button>
              )}
              </div>          
              </div>
            <div className="search-input">
              <button>
                <img src={searchicon} />
              </button>
              {/* <input type="search" placeholder="Search..." /> */}
              <input
                type="search"
                placeholder="Search..."
                ref={searchInputRef}
                value={searchKey}
                onChange={async (e) => {
                  setSearchKey(e?.target?.value)
                }}
              />
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
<div className="selectors-container">
  <div className="grid">
              <h7 style={{fontWeight: "bold", marginLeft: '8px', marginBottom: '5px'}}>Select Lot</h7>
              <select
                className="minimal selectors"
                value={selectedLot}
                onChange={(e) => {
                  setSelectedLot(e.target?.value);
                  allCitationsinfo(1, {lotId: e.target?.value});
                }}
              >
                <option value="All">All Lots</option>
                {lots?.map((lot, idx) => (
                  <option key={idx} value={lot?._id}>
                    {lot?.address}
                  </option>
                ))}
              </select>
  </div>
  {/* ####################################### */}
  <div className="grid">
              <h7 style={{fontWeight: "bold", marginLeft: '2px', marginBottom: '5px'}}>Select Notice Status</h7>
              <select
                className="minimal selectors"
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target?.value);
                  allCitationsinfo(1, { status: e.target?.value});
                }}
              >
                <option value="All">All Statuses</option>
                {['paid', 'pending', 'refunded', 'voided', 'dispute-lost']?.map((status, idx) => (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                ))}
                </select>
  </div>
  {/* ######################################### */}
  <div className="grid">
              <h7 style={{fontWeight: "bold", marginLeft: '8px', marginBottom: '5px'}} >Select Tag type</h7>
              <select
                className="minimal selectors"
                value={selectedCitation_type}
                onChange={(e) => {
                  setSelectedCitation_type(e.target?.value);
                  allCitationsinfo(1, { citation_type: e.target?.value});
                }}
              >
                <option value="All">All Tags</option>
                {['tag_vehicle', 'mail_in', 'taggrNotice', 'enforcement_lite']?.map((status, idx) => (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                ))}
                </select>
  </div>
  {/* ######################################### */}
  <div className="grid">
              <h7 style={{fontWeight: "bold", marginLeft: '8px', marginBottom: '5px'}}>List by</h7>
              <select
                className="minimal selectors"
                value={selectedDate_type}
                onChange={(e) => {
                  setSelectedDate_type(e.target?.value);
                  allCitationsinfo(1, { date_type: e.target?.value});
                }}
              >
                <option value="All">All Dates</option>
                {[{name: 'Created At', value: 'createdAt'}, {name: 'Payment Date', value: 'payment_date'}]?.map((status, idx) => (
                  <option key={idx} value={status?.value}>
                    {status?.name}
                  </option>
                ))}
                </select>
  </div>
  {/* ######################################### */}
</div>
             {data?.citation?.length > 0 ? <DataGrid
              rows={data?.citation?.map((citation, index) => (citation)
              )}
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              pageSize={pageState.pageSize}
              rowsPerPageOptions={[10]}
              // hideFooterPagination
              // hideFooterSelectedRowCount
              hideFooter
              pagination
              onPageChange={(params) => setPageState({ currentPage: params.page })}
              rowCount={pageState.total}
              
              paginationMode="server"
              loading={props.loading}
              getRowId={(row) => row._id}
            /> :  <h2 style={{
              maxWidth: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "10%"
            }}
            > No record found</h2>}

              {data?.citation?.length > 0 && !pageState?.isSearched && (
                // console.log("ooooo"),
                <div className='pagination-block'>
                  <Pagination
                    hideOnSinglePage
                    defaultCurrent={pageState?.defaultCurrentPage}
                    onChange={page => setPageState({ currentPage: page })}
                    current={pageState?.currentPage}
                    pageSize={pageState?.pageSize}
                    total={pageState?.total}
                    showSizeChanger={false}
                  />
                </div>
              )}


              {/* <div className='pagination-block'>
                <Pagination
                  hideOnSinglePage
                  defaultCurrent={pageState?.defaultCurrentPage}
                  onChange={page => setPageState({ currentPage: page })}
                  current={pageState?.currentPage}
                  pageSize={pageState?.pageSize}
                  total={pageState?.total}
                  showSizeChanger={false}
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Refund_modal
        show={pageState?.showModal}
        // onClose={() => setPageState({ showModal: false })}
        onClose={handleModalClose}
        setReason={(e) => setReason(e?.target?.value)}
        amount={pageState?.amount}
        setAmount={(amount) => setPageState({amount})}
        reason={reason}
        setrefund_role={setrefund_role}
        refund_role={refund_role}
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
        maximumRefundAmount={pageState?.maximumRefundAmount}
        fullRefundAmount={pageState?.fullRefundAmount}
        onSubmit={onDeleteModalSubmit}
        title={pageState?.refund_popup_title}
        header_title="Refund"
        operator_name = {pageState?.operator_name}
      />
      <Refund_modal
        show={pageState?.show_void_modal}
        onClose={() => {
          setPageState({ show_void_modal: false })
          setReason('')
          setTaggrFee(false)
        }}
        setReason={(e) => setReason(e?.target?.value)}
        onSubmit={on_void_citation_modal_submit}
        amount={pageState?.amount}
        reason={reason}
        title={pageState?.void_popup_title}
        header_title="Void"
        citation_type={pageState?.citation_type}
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
      />

      <TextModal title="Send Dispute Form" show={showTextModal} onClose={toggleTextModal} onSubmit={handleTextSend} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
      <PayByCheque title="Cheque Information" show={showPBCModal} onClose={togglePBCModal} onSubmit={handlePBC} chequeId={chequeId} setChequeId={setChequeId} />
    </>
  );
}
