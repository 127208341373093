import { useEffect, useRef } from "react";

const ConfirmAction = ({ onConfirm, onCancel }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    // Close when clicking outside
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onCancel();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onCancel]);

  return (
    <div ref={popupRef} className="confirm-popup">
      <p>Want to reset?</p>
      <div className="confirm-buttons">
        <button className="confirm-yes" onClick={onConfirm}>
          Yes
        </button>
        <button className="confirm-no" onClick={onCancel}>
          No
        </button>
      </div>
    </div>
  );
};

export default ConfirmAction;
