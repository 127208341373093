import React, { useEffect, useState } from "react";
import { Modal, TextField, Button, IconButton, Box, SvgIcon, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import WebhookIcon from '@mui/icons-material/Webhook';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import AdminHeader from "../Citations-Header/container";
import "./WebhookSettings.css"; // Custom CSS file
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../store/actioncreator";

const DiscordIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24" sx={{ fill: '#5865F2', transition: '0.3s', '&:hover': { fill: '#404EED' } }}>
    <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
  </SvgIcon>
);

const WebhookSettings = (props) => {
  const [lots, setLots] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedLot, setSelectedLot] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState("");
  const dispatch = useDispatch()

  useEffect(() => {
    fetchWebhooks()
  }, [])

  async function fetchWebhooks() {
    dispatch(setLoading(true))
    try {
      const { value } = await props.getAllLotsAndWebhooks();
      if (value?.success) {
        setLots(value?.webhooks);
      }
      else {
        dispatch(setAlert('error', value?.message))
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
    dispatch(setLoading(false))
  }

  const handleEdit = (lot) => {
    console.log(lot, "<==lot")
    setSelectedLot({_id: lot?._id, lotname: lot?.lotname, address: lot?.address, webhook: lot?.webhook});
    setWebhookUrl(lot?.webhook?.webhookUrl);
    setModalOpen(true);
  };

  const handleSave = async (isLoading=false, id=null, isActive=false) => {
    dispatch(setLoading(isLoading))
    try {
      if (!webhookUrl || !webhookUrl.startsWith("http")) {
        dispatch(setAlert("error", "Invalid webhook URL. Please enter a valid URL."));
        return;
      }
      console.log(isLoading, id, isActive, "<===items")
      const lotId = id || selectedLot?._id;
      setLots((prevLots) =>
        prevLots.map((lot) =>
          lot._id === lotId ? { ...lot, webhook: { ...lot.webhook, webhookUrl, isActive } } : lot
        )
      );
      let obj = {
        lotId,
        webhookUrl,
      }
      if(!isLoading){
        obj['isActive']= isActive
      }
      const { value } = await props.createOrUpdateWebhook(obj);
      if (!value?.success) {
        fetchWebhooks()
        dispatch(setAlert('error', value?.message))
      }
      !id && setModalOpen(false);
    } catch (error) {
      fetchWebhooks()
      dispatch(setAlert('error', error?.message))
      !id && setModalOpen(false);
    } finally{
      dispatch(setLoading(false))
    }
  };

  const handleCopy = (url) => {
    if(! navigator.clipboard){
      dispatch(setAlert('error', "Your browser doesn't allow to copy url on your clipboard!"))
      return 
    }
    navigator.clipboard.writeText(url);
    dispatch(setAlert('info', "Webhook URL copied!"))
  };

  const filteredLots = lots.filter((lot) =>
    lot.lotname?.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    { field: "lotname", headerName: "Lot Name", flex: 1, minWidth: 150 },
    { field: "address", headerName: "Lot Address", flex: 1, minWidth: 150 },
    {
      field: "webhook",
      headerName: "Webhook URL",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="webhook-url-cell">
          {params.value?.webhookUrl?.length > 0 ? (
            <span className="masked-url">••••••••••••
              <IconButton onClick={() => handleCopy(params.value?.webhookUrl)} size="small">
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </span>
          ) : "-"}
        </div>
      ),
    },
    {
      field: "active",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        params?.row?.webhook?.isActive ? (
          <CheckCircleIcon className="status-active" />
        ) : (
          <CancelIcon className="status-inactive" />
        )
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleEdit(params.row)} size="small">
            <EditIcon />
          </IconButton>
          <Switch
            checked={params.row?.webhook?.isActive}
            onChange={() => handleSave(false, params.row?._id, !params.row?.webhook?.isActive)}
            color="primary"
            disabled={!params.row?.webhook?.webhookUrl || params.row?.webhook?.webhookUrl?.length <= 0}
          />
        </div>

      ),
    },
  ];

  return (
    <section className="adminCitations">
      <AdminHeader />
      <div className="citationsSection paddzero" id="profile-wrapper">
        <div className="webhook-settings-container">
          <h2 className="webhook-settings-title"><DiscordIcon fontSize="large" /> Webhooks</h2>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search Lots"
            onChange={(e) => setSearch(e.target.value)}
            className="search-bar my-3"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <DataGrid
            rows={filteredLots}
            getRowId={row => row?._id}
            columns={columns}
            pageSize={5}
            autoHeight
          />
        </div>
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box className="edit-webhook-modal">
          <h3 className="modal-title">Edit Webhook for {selectedLot?.lotname}</h3>
          <TextField
            fullWidth
            label="Webhook URL"
            variant="outlined"
            value={webhookUrl}
            onChange={(e) => setWebhookUrl(e.target.value)}
            className="webhook-input"
          />
          <div className="modal-actions my-3">
            <Button
              variant="contained"
              color="dark"
              onClick={() => handleSave(true, undefined, selectedLot?.webhook?.isActive)}
              className="save-button"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setModalOpen(false)}
              className="cancel-button"
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </section>
  );
};

export default WebhookSettings;
